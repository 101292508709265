
<div class="wrapper">
    <div class="sidebar" data-color="seniat" data-background-color="seniat" data-image="./assets/img/torre-seniat.png">
        <app-sidebar-cmp></app-sidebar-cmp>
        <div class="sidebar-background" style="background-image: url(assets/img/torre-seniat.png);"></div>
    </div>
    <div class="main-panel">
        <app-navbar-cmp></app-navbar-cmp>
        <router-outlet></router-outlet>
        <div *ngIf="!isMap()">
            <app-footer-cmp></app-footer-cmp>
        </div>
    </div>
    <!-- <app-fixedplugin></app-fixedplugin> -->
</div>