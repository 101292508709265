<div class="sidebar-wrapper">
  <img width="100%" src="/assets/img/seniatw.png"/>
    <div class="user">
        <div class="photo">
          <ngx-avatar name="{{user.fullname}}" size="34"></ngx-avatar>
        </div>
        <div class="user-info">
            <a data-toggle="collapse" href="#collapseExample" class="nav-link">
                <p>
                    {{user.fullname | slice:0:18}}{{user.fullname && user.fullname.length >= 18? '...': ''}}
                    <b class="caret"></b>
                </p>
            </a>
            <div class="collapse" id="collapseExample">
                <ul class="nav">
                    <li class="nav-item">
                        <a routerLink="/profile" class="nav-link">
                            <span class="sidebar-mini">MC</span>
                            <span class="sidebar-normal">Mi Cuenta</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link" (click)="logOut($event)">
                            <span class="sidebar-mini">CS</span>
                            <span class="sidebar-normal">Cerrar Sesión</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div *ngIf="isMobileMenu()" class="d-none">
      <form class="navbar-form">
        <span class="bmd-form-group"><div class="input-group no-border">
          <input type="text" value="" class="form-control" placeholder="Search...">
          <button mat-raised-button type="submit" class="btn btn-white btn-round btn-just-icon">
            <i class="material-icons">search</i>
            <div class="ripple-container"></div>
          </button>
        </div></span>
      </form>
      <ul class="nav navbar-nav nav-mobile-menu">
        <li class="nav-item">
          <a class="nav-link" href="#pablo">
            <i class="material-icons">dashboard</i>
            <p>
              <span class="d-lg-none d-md-block">Stats</span>
            </p>
          </a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link" href="#pablo" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="material-icons">notifications</i>
            <span class="notification">5</span>
            <p>
              <span class="d-lg-none d-md-block">Some Actions</span>
            </p>
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
            <a class="dropdown-item" href="#">Mike John responded to your email</a>
            <a class="dropdown-item" href="#">You have 5 new tasks</a>
            <a class="dropdown-item" href="#">You're now friend with Andrew</a>
            <a class="dropdown-item" href="#">Another Notification</a>
            <a class="dropdown-item" href="#">Another One</a>
          </div>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#pablo">
            <i class="material-icons">person</i>
            <p>
              <span class="d-lg-none d-md-block">Account</span>
            </p>
          </a>
        </li>
      </ul>
    </div>

    <ul class="nav">
        <li routerLinkActive="active" *ngFor="let menuitem of menuItems" class="nav-item">
            <!--If is a single link-->
            <a [routerLink]="[menuitem.path]" *ngIf="menuitem.type === 'link' && auth.hasAccess(menuitem.ability)" class="nav-link">
                <i class="material-icons">{{menuitem.icontype}}</i>
                <p matTooltip="{{menuitem.title}}" [matTooltipPosition]="'right'">{{menuitem.title | truncate}}</p>
            </a>
            <!--If it have a submenu-->
            <a data-toggle="collapse" href="#{{menuitem.collapse}}" *ngIf="menuitem.type === 'sub' && auth.hasAccess(menuitem.ability)" (click)="updatePS()" class="nav-link">
                <i class="material-icons">{{menuitem.icontype}}</i>
                <p matTooltip="{{menuitem.title}}" [matTooltipPosition]="'right'">{{menuitem.title | truncate}}<b class="caret"></b></p>
            </a>

            <!--Display the submenu items-->
            <div id="{{menuitem.collapse}}" class="collapse" *ngIf="menuitem.type === 'sub'">
                <ul class="nav">
                    <li routerLinkActive="active" *ngFor="let childitem of menuitem.children" class="nav-item">
                        <a [routerLink]="[menuitem.path, childitem.path]" class="nav-link" *ngIf="auth.hasAccess(childitem.ability)" matTooltip="{{childitem.title}}" [matTooltipPosition]="'right'">
                            <span class="sidebar-mini">{{childitem.ab}}</span>
                            <span class="sidebar-normal">{{childitem.title | truncate}}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </li>
        <li class="nav-item d-none">
            <a href="http://md-pro-angular.creative-tim.com/documentation/tutorial?ref=md-pro-archive" class="nav-link">
                <i class="material-icons">school</i>
                <p>Documentation</p>
            </a>
        </li>
    </ul>
</div>