import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvService } from './env.service';
import { map, catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { TokenService } from './token.service';
import { NotificationService } from './notification.service';
import { Router } from '@angular/router';

interface Request {
  uri: string;
  options: {
    responseType: 'json';
    body: any;
    headers: HttpHeaders;
  };
}

interface ExtraArgs {
  server?: string;
  extraUri?: string | any;
  notify?: boolean;
  file?: boolean;
  extras?: any;
  queryParams?: any;
  externalRequest?: boolean;
  
}

@Injectable()

export class HttpService {

  defaultErrorMessage = 'Parece que algo ha ido mal, intente nuevamente.';

  defaultExtraArgs: ExtraArgs = {
    server: 'back',
    extraUri: '',
    notify: false,
    file: false,
    queryParams: null,
    externalRequest: false,
  };

  constructor(
    private router: Router,
    private http: HttpClient,
    private env: EnvService,
    private token: TokenService,
    private notification: NotificationService,
  ) { }

  private prepare(api: string, params: any = null, extraArgs: ExtraArgs = this.defaultExtraArgs): Request {
    const server = extraArgs.server ? extraArgs.server : this.defaultExtraArgs.server;
    const extraUri = extraArgs.extraUri ? extraArgs.extraUri : this.defaultExtraArgs.extraUri;
    const response: any = {};
    const urlData = this.env.api(server, api) + extraUri;
    //console.log(extraUri);
    response['uri'] = urlData.trim();
    response['options'] = {};
    response['options']['responseType'] = extraArgs.file ? 'blob' : 'json';
    response['options']['reportProgress'] = true;

    if (this.token.isValid() && !extraArgs.externalRequest) {
      response['options']['headers'] = new HttpHeaders({
        Authorization: `Bearer ${this.token.get('access_token')}`,
      });
    }

    if (params !== null) {
      response['options']['body'] = params;
    }

    if (extraArgs.queryParams) {
      response['uri'] += `?${new URLSearchParams(extraArgs.queryParams).toString()}`;
    }

    return response;
  }

  request(
    method: string,
    api: string,
    params?: any,
    extraArgs: ExtraArgs = this.defaultExtraArgs
  ): Observable<any> {
    const args = this.prepare(api, params, extraArgs);
    return this.http.request(method, args.uri, args.options)
      .pipe(map((response) => {
        if (response['status'] === 200) {
          if (
            extraArgs.server != 'auth' && api != 'scpUpload' && api != 'saime' &&
            api != 'ldap' && api != "changePassword" && api != "docIdentity" &&
            api != "catalogues" && api != "geographicalEntities" && api != "upload" && 
            api != "goodType" && api != "deleteFiles" && api != "createComiso" && 
            api != "facture" && api != "createLineComiso" && api != "documentDowload" && 
            api != "allDesglose" && api != "invoice" && api != "validationVehicle" && 
            api != "administrativeUnits" && api != "documentRequire" && api != "managementGood" &&
            api != "generalProperty" 
          ) {
            if (method === 'PUT') {
              this.notification.show('Actualizado correctamente!', 'success');
            } else if (method === 'POST') {
              //this.notification.show('Creado correctamente!', 'success');
            } else if (method === 'DELETE') {
              this.notification.show('Eliminado correctamente!', 'success');
            } 
          }
          if (api === "deleteFiles") {
            this.notification.show('Eliminado correctamente!', 'success');
          }
          if (response['message'] && extraArgs.notify) {
            this.notification.show(response['message'], 'success');
          }
        } else if (response['status'] === 422) {
          return this.handleInvalidForm(response);
        } else {
          if (api != "generalProperty") {
            this.notification.show(response['message'], 'danger', undefined, undefined, 'error_outline');
          }
          else{
            this.notification.show('El Nº del Bien Ingresado no existe', 'danger', undefined, undefined, 'error_outline');
          }
          return this.handlerError(response);
        }
        return response['content'];
      }),
        catchError((error) => throwError(
          this.handlerError(error)
        )));
  }

  encodeGetParams(input: any) {
    const res = Object.entries(input)
      .filter(kv => kv[1] !== null && kv[1] !== undefined)
      .map(kv => kv.map(encodeURIComponent)
      .join("=")).join("&");
    return (res === '') ? '' : `?${res}`;
  }



  handlerError(error) {
    let message = error['error'];
    if (!message.error) {
      //message = this.defaultErrorMessage;
      this.handleInvalidForm(error);
    } else {
      // this.notification.show(message, 'danger', undefined, undefined, 'error_outline');
      return message;
    }
    if (error['status'] === 401) {
      this.router.navigate(['login']);
    }
  }

  handleInvalidForm(response) {
    this.notification.show(response.message, 'danger', undefined, undefined, 'error_outline');

    return response;
  }
}
