<nav #navbar class="navbar navbar-expand-lg navbar-transparent  navbar-absolute" style="z-index: -1;">
  <div class="container-fluid">
    <div class="navbar-wrapper">
      <div class="navbar-minimize">
        <button mat-raised-button (click)="minimizeSidebar()" class="btn btn-just-icon btn-white btn-fab btn-round">
          <i class="material-icons text_align-center visible-on-sidebar-regular">more_vert</i>
          <i class="material-icons design_bullet-list-67 visible-on-sidebar-mini">view_list</i>
        </button>
      </div>
      <a class="navbar-brand" href="{{getPath()}}"> {{getTitle()}}</a>
    </div>
  </div>
</nav>
