// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  servers: {
    back: 'http://bienes-api.washiru.com.ve',
    auth: 'http://bienes-api.washiru.com.ve',
  },
  app: 'Sistema de Gestión de Bienes',
  active: {
    register: true,
    password: true,
    lock: true,
  },
  ports:{
    qr: ':8010'
  }
};
